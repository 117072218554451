export type ContentNode = {
  type:
    | "image"
    | "paragraph"
    | "heading1"
    | "heading2"
    | "heading3"
    | "video"
    | "orderedList"
    | "unorderedList"
    | "numberList"
    | "squareList"
    | "page"
    | "listItem"
    | "subparagraph"
    | "proTip";
  content?: string;
  children?: ContentNode[];
  indent?: number;
  space?: number;
  bottom?: number;
  position?: string;
};

const SportsBetting101: ContentNode = {
  type: "page",
  children: [
    {
      type: "heading2",
      content: "sports_betting_101_title_1",
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_1_desc",
    },
    {
      type: "heading2",
      content: "sports_betting_101_title_2",
      space: 8,
    },
    {
      type: "image",
      content: "/images/oddstypes.png",
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_2_desc",
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_2_sub_1",
      indent: 6.5,
      space: 3,
      bottom: 1
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_1_desc_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_1_desc_2",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_1_desc_3",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_1_desc_4",
          indent: 13,
        },
      ],
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_2_sub_2",
      indent: 6.5,
      space: 3,
      bottom: 1
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_2_desc_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_2_desc_2",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_2_desc_3",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_2_desc_4",
          indent: 13,
        },
      ],
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_2_sub_3",
      indent: 6.5,
      space: 3,
      bottom: 1
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_3_desc_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_3_desc_2",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_3_desc_3",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_3_desc_4",
          indent: 13,
        },
      ],
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_2_sub_4",
      indent: 6.5,
      space: 3,
      bottom: 1
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_4_desc_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_4_desc_2",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_4_desc_3",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_4_desc_4",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_4_desc_5",
          indent: 13,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_2_desc_2",
    },
    {
      type: "heading2",
      content: "sports_betting_101_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_3_desc",
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_3_desc_sub_title_1",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_3_desc_sub_title_1_desc",
      indent: 6.5,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_3_desc_sub_title_2",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_3_desc_sub_title_2_desc",
      indent: 6.5,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_3_desc_sub_title_3",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_3_desc_sub_title_3_desc",
      indent: 6.5,
    },
    {
      type: "heading2",
      content: "sports_betting_101_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_4_desc",
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_4_sub_1",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_4_sub_1_desc",
      indent: 9,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_4_sub_2",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_4_sub_2_desc",
      indent: 9,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_4_sub_3",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_4_sub_3_desc",
      indent: 9,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_4_sub_4",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_4_sub_4_desc",
      indent: 9,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_4_sub_5",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_4_sub_5_desc",
      indent: 9,
    },
    {
      type: "heading2",
      content: "sports_betting_101_title_5",
      space: 8,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_5_sub_1",
      space: 3,
      indent: 6.5,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_5_sub_1_desc",
      indent: 6.5,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "example",
          indent: 13,
          space: 1,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_1_desc_1",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_1_desc_2",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_1_desc_3",
          indent: 19.5,
        },
      ],
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_5_sub_2",
      space: 3,
      indent: 6.5,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_5_sub_2_desc",
      indent: 6.5,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "example",
          indent: 13,
          space: 1,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_2_desc_1",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_2_desc_2",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_2_desc_3",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_2_desc_4",
          indent: 19.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_5_sub_2_desc_5",
      indent: 6.5,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_5_sub_3",
      space: 3,
      indent: 6.5,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_5_sub_3_desc",
      indent: 6.5,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "example",
          indent: 13,
          space: 1,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_3_desc_1",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_3_desc_2",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_3_desc_3",
          indent: 19.5,
          space: 3,
        },
      ],
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_5_sub_4",
      space: 3,
      indent: 6.5,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_5_sub_4_desc",
      indent: 6.5,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_4_desc_1",
          indent: 13,
          space: 1,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_4_desc_1_sub_1",
          indent: 19.5,
          space: 3,
        },
      ],
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_4_desc_2",
          indent: 13,
          space: 1,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_4_desc_2_sub_1",
          indent: 19.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_5_sub_4_desc_3",
    },
  ],
};

const ArbitrageBetting: ContentNode = {
  type: "page",
  children: [
    {
      type: "heading2",
      content: "arbitrage_betting_title_1",
    },
    {
      type: "paragraph",
      content: "arbitrage_betting_title_1_desc",
    },
    {
      type: "heading2",
      content: "arbitrage_betting_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_betting_title_2_desc",
    },
    {
      type: "heading2",
      content: "arbitrage_betting_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_betting_title_3_desc",
    },
  ],
};

const PositiveEV: ContentNode = {
  type: "page",
  children: [
    {
      type: "heading2",
      content: "positive_ev_betting_title_1",
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_1_desc",
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_1_desc_sub",
      indent: 2,
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_1_desc_2",
    },
    {
      type: "heading2",
      content: "positive_ev_betting_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_2_desc",
    },
    {
      type: "heading2",
      content: "positive_ev_betting_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_3_desc",
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_3_desc_fomula_1",
      indent: 2,
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_3_desc_fomula_2",
      indent: 2,
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_3_desc_fomula_3",
      indent: 2,
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_3_desc_2",
    },
  ],
};

const Filters: ContentNode = {
  type: "page",
  children: [
    {
      type: "heading2",
      content: "filter_title_1",
    },
    {
      type: "paragraph",
      content: "filter_title_1_desc",
    },
    {
      type: "heading2",
      content: "filter_title_2",
      space: 8,
    },
    {
      type: "numberList",
      children: [
        {
          type: "listItem",
          content: "filter_title_2_desc_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "filter_title_2_desc_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "filter_title_2_desc_3",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "filter_title_2_desc_4",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "filter_title_2_desc_5",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "filter_title_2_desc_6",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "filter_title_2_desc_7",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "filter_title_2_desc_8",
          indent: 6.5,
        },
      ],
    },

    {
      type: "heading2",
      content: "filter_title_3",
      space: 8,
    },
    {
      type: "numberList",
      children: [
        {
          type: "listItem",
          content: "filter_title_3_desc_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "filter_title_3_desc_2",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "filter_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "filter_title_4_desc",
      space: 2,
    },
    {
      type: "numberList",
      children: [
        {
          type: "listItem",
          content: "filter_title_4_desc_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "filter_title_4_desc_2",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "filter_title_5",
      space: 8,
    },
    {
      type: "paragraph",
      content: "filter_title_5_desc",
      space: 2,
    },
    {
      type: "numberList",
      children: [
        {
          type: "listItem",
          content: "filter_title_5_desc_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "filter_title_5_desc_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "filter_title_5_desc_3",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "filter_title_6",
      space: 8,
    },
    {
      type: "paragraph",
      content: "filter_title_6_desc",
    },
  ],
};

const OddsScreenFilters: ContentNode = {
  type: "page",
  children: [
    {
      type: "paragraph",
      content: "odds_screen_filters_desc",
    },
    {
      type: "heading2",
      content: "odds_screen_filters_title_1",
      space: 8,
    },
    {
      type: "paragraph",
      content: "odds_screen_filters_title_1_desc",
    },
    {
      type: "heading2",
      content: "odds_screen_filters_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "odds_screen_filters_title_2_desc",
    },
    {
      type: "heading2",
      content: "odds_screen_filters_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "odds_screen_filters_title_3_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "odds_screen_filters_title_3_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "odds_screen_filters_title_3_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "odds_screen_filters_title_3_list_3",
          indent: 6.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "odds_screen_filters_title_3_desc_1",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "odds_screen_filters_title_3_list_4",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "odds_screen_filters_title_3_list_5",
          indent: 6.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "odds_screen_filters_title_3_desc_2",
    },
    {
      type: "heading2",
      content: "odds_screen_filters_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "odds_screen_filters_title_4_desc",
    },
    {
      type: "heading2",
      content: "odds_screen_filters_title_5",
      space: 8,
    },
    {
      type: "paragraph",
      content: "odds_screen_filters_title_5_desc",
    },
  ],
};

const OddsScreenPage: ContentNode = {
  type: "page",
  children: [
    {
      type: "heading2",
      content: "odds_screen_page_title_1",
    },
    {
      type: "paragraph",
      content: "odds_screen_page_title_1_desc",
    },
    {
      type: "heading2",
      content: "odds_screen_page_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "odds_screen_page_title_2_desc_1",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "odds_screen_page_title_2_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "odds_screen_page_title_2_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "odds_screen_page_title_2_list_3",
          indent: 6.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "odds_screen_page_title_2_desc_2",
    },
    {
      type: "heading2",
      content: "odds_screen_page_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "odds_screen_page_title_3_desc_1",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "odds_screen_page_title_3_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "odds_screen_page_title_3_list_2",
          indent: 6.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "odds_screen_page_title_3_desc_2",
    },
  ],
};

const ArbitrageFilters: ContentNode = {
  type: "page",
  children: [
    {
      type: "heading2",
      content: "arbitrage_filters_title_1",
    },
    {
      type: "paragraph",
      content: "arbitrage_filters_title_1_desc",
    },
    {
      type: "heading2",
      content: "arbitrage_filters_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_filters_title_2_desc",
    },
    {
      type: "heading2",
      content: "arbitrage_filters_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_filters_title_3_desc_1",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_filters_title_3_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_3_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_3_list_3",
          indent: 6.5,
          space: 3,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_filters_title_3_list_3_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_3_list_3_2",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_3_list_3_3",
          indent: 13,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "arbitrage_filters_title_3_desc_2",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_filters_title_3_desc_2_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_3_desc_2_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_3_desc_2_list_3",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_3_desc_2_list_4",
          indent: 6.5,
          space: 3,
        },
      ],
    },
    {
      type: "proTip",
      content: "arbitrage_filters_title_3_desc_2_list_4_pro",
      indent: 6.5,
    },
    {
      type: "heading2",
      content: "arbitrage_filters_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_filters_title_4_desc_1",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_filters_title_4_desc_1_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_4_desc_1_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_4_desc_1_list_3",
          indent: 6.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "arbitrage_filters_title_4_desc_2",
    },
    {
      type: "heading2",
      content: "arbitrage_filters_title_5",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_filters_title_5_desc_1",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_filters_title_5_desc_1_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_5_desc_1_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_5_desc_1_list_3",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_5_desc_1_list_4",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_5_desc_1_list_5",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_5_desc_1_list_6",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_5_desc_1_list_7",
          indent: 6.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "arbitrage_filters_title_5_desc_2",
    },
    {
      type: "heading2",
      content: "arbitrage_filters_title_6",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_filters_title_6_desc_1",
    },
    {
      type: "heading2",
      content: "arbitrage_filters_title_7",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_filters_title_7_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_filters_title_7_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_7_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_7_list_3",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_7_list_4",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_7_list_5",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_7_list_6",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_filters_title_7_list_7",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "arbitrage_filters_title_8",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_filters_title_8_desc",
    },
    {
      type: "heading2",
      content: "arbitrage_filters_title_9",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_filters_title_9_desc",
    },
  ],
};

const ArbitragePage: ContentNode = {
  type: "page",
  children: [
    {
      type: "paragraph",
      content: "arbitrage_page_desc",
    },
    {
      type: "heading2",
      content: "arbitrage_page_title_1",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_page_title_1_desc",
    },
    {
      type: "heading2",
      content: "arbitrage_page_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_page_title_2_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_page_title_2_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_page_title_2_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_page_title_2_list_3",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "arbitrage_page_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_page_title_3_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_page_title_3_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_page_title_3_list_2",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "arbitrage_page_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_page_title_4_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_page_title_4_list",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "arbitrage_page_title_5",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_page_title_5_desc",
    },
    {
      type: "heading2",
      content: "arbitrage_page_title_6",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_page_title_6_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_page_title_6_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_page_title_6_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "arbitrage_page_title_6_list_3",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "arbitrage_page_title_7",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_page_title_7_desc",
    },
  ],
};

const PositiveEVFilters: ContentNode = {
  type: "page",
  children: [
    {
      type: "paragraph",
      content: "positive_ev_filters_desc",
    },
    {
      type: "heading2",
      content: "positive_ev_filters_title_1",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_filters_title_1_desc",
    },
    {
      type: "heading2",
      content: "positive_ev_filters_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_filters_title_2_desc",
    },
    {
      type: "heading2",
      content: "positive_ev_filters_title_3",
      space: 8,
    },
    {
      type: "heading3",
      content: "positive_ev_filters_title_3_sub_title",
      space: 3,
      indent: 6.5,
    },
    {
      type: "paragraph",
      content: "positive_ev_filters_title_3_desc_1",
      indent: 6.5,
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_filters_title_3_list",
          indent: 13,
          space: 3,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_filters_title_3_list_1",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_filters_title_3_list_2",
          indent: 19.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "positive_ev_filters_title_3_desc_2",
      indent: 6.5,
    },
    {
      type: "heading2",
      content: "positive_ev_filters_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_filters_title_4_desc_1",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_filters_title_4_desc_1_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_filters_title_4_desc_1_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_filters_title_4_desc_1_list_3",
          indent: 6.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "positive_ev_filters_title_4_desc_2",
      space: 2,
    },
    {
      type: "numberList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_filters_title_4_desc_2_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_filters_title_4_desc_2_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_filters_title_4_desc_2_list_3",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "positive_ev_filters_title_5",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_filters_title_5_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_filters_title_5_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_filters_title_5_list_2",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "positive_ev_filters_title_6",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_filters_title_6_desc",
    },
    {
      type: "heading2",
      content: "positive_ev_filters_title_7",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_filters_title_7_desc",
    },
  ],
};

const LowHoldPage: ContentNode = {
  type: "page",
  children: [
    {
      type: "paragraph",
      content: "low_holds_page_desc",
    },
    {
      type: "heading2",
      content: "low_holds_page_title_1",
      space: 8,
    },
    {
      type: "paragraph",
      content: "low_holds_page_title_1_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "low_holds_page_title_1_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "low_holds_page_title_1_list_2",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "low_holds_page_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "low_holds_page_title_2_desc",
    },
    {
      type: "heading2",
      content: "low_holds_page_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "low_holds_page_title_3_desc",
    },
    {
      type: "heading2",
      content: "low_holds_page_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "low_holds_page_title_4_desc",
    },
    {
      type: "heading2",
      content: "low_holds_page_title_5",
      space: 8,
    },
    {
      type: "paragraph",
      content: "low_holds_page_title_5_desc",
    },
    {
      type: "heading2",
      content: "low_holds_page_title_6",
      space: 8,
    },
    {
      type: "paragraph",
      content: "low_holds_page_title_6_desc",
    },
  ],
};

const MiddlesPage: ContentNode = {
  type: "page",
  children: [
    {
      type: "paragraph",
      content: "middles_page_desc",
    },
    {
      type: "heading2",
      content: "middles_page_title_1",
      space: 8,
    },
    {
      type: "paragraph",
      content: "middles_page_title_1_desc",
    },
    {
      type: "heading2",
      content: "middles_page_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "middles_page_title_2_desc",
      space: 2,
    },
    {
      type: "paragraph",
      content: "middles_page_title_2_list",
      indent: 6.5,
    },
    {
      type: "heading2",
      content: "middles_page_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "middles_page_title_3_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "middles_page_title_3_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "middles_page_title_3_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "middles_page_title_3_list_3",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "middles_page_title_3_list_4",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "middles_page_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "middles_page_title_4_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "middles_page_title_4_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "middles_page_title_4_list_2",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "middles_page_title_5",
      space: 8,
    },
    {
      type: "paragraph",
      content: "middles_page_title_5_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "middles_page_title_5_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "middles_page_title_5_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "middles_page_title_5_list_3",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "middles_page_title_6",
      space: 8,
    },
    {
      type: "paragraph",
      content: "middles_page_title_6_desc",
    },
  ],
};

const FreeBetConverter: ContentNode = {
  type: "page",
  children: [
    {
      type: "paragraph",
      content: "free_bet_converter_desc",
    },
    {
      type: "heading2",
      content: "free_bet_converter_title_1",
      space: 8,
    },
    {
      type: "paragraph",
      content: "free_bet_converter_title_1_desc_1",
      space: 2,
    },
    {
      type: "numberList",
      children: [
        {
          type: "listItem",
          content: "free_bet_converter_title_1_desc_1_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "free_bet_converter_title_1_desc_1_list_2",
          indent: 6.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "free_bet_converter_title_1_desc_2",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "free_bet_converter_title_1_desc_2_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "free_bet_converter_title_1_desc_2_list_2",
          indent: 6.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "free_bet_converter_title_1_desc_3",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "free_bet_converter_title_1_desc_3_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "free_bet_converter_title_1_desc_3_list_2",
          indent: 6.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "free_bet_converter_title_1_desc_4",
    },
    {
      type: "heading2",
      content: "free_bet_converter_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "free_bet_converter_title_2_desc",
      space: 2,
    },
    {
      type: "numberList",
      children: [
        {
          type: "listItem",
          content: "free_bet_converter_title_2_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "free_bet_converter_title_2_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "free_bet_converter_title_2_list_3",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "free_bet_converter_title_2_list_4",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "free_bet_converter_title_2_list_5",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "free_bet_converter_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "free_bet_converter_title_3_desc",
    },
    {
      type: "heading2",
      content: "free_bet_converter_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "free_bet_converter_title_4_desc",
    },
  ],
};

const Setting: ContentNode = {
  type: "page",
  children: [
    {
      type: "heading2",
      content: "setting_title_1",
      space: 8,
    },
    {
      type: "paragraph",
      content: "setting_title_1_desc",
    },
    {
      type: "heading2",
      content: "setting_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "setting_title_2_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "setting_title_2_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "setting_title_2_list_2",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "setting_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "setting_title_3_desc",
    },
    {
      type: "heading2",
      content: "setting_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "setting_title_4_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "setting_title_4_list",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "setting_title_5",
      space: 8,
    },
    {
      type: "paragraph",
      content: "setting_title_5_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "setting_title_5_list",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "setting_title_6",
      space: 8,
    },
    {
      type: "paragraph",
      content: "setting_title_6_desc",
    },
    {
      type: "heading2",
      content: "setting_title_7",
      space: 8,
    },
    {
      type: "paragraph",
      content: "setting_title_7_desc",
    },
    {
      type: "heading2",
      content: "setting_title_8",
      space: 8,
    },
    {
      type: "paragraph",
      content: "setting_title_8_desc",
      space: 2,
    },
    {
      type: "proTip",
      content: "setting_title_8_desc_pro",
    },
  ],
};

const AdvancedFilters: ContentNode = {
  type: "page",
  children: [
    {
      type: "video",
      content: "https://player.vimeo.com/video/1013172530?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      type: "heading2",
      content: "advanced_filter_title_1",
      position: "center",
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1013176996?badge=0&autopause=0&player_id=0&app_id=58479",
      space: 6,
    },
    {
      type: "heading2",
      content: "advanced_filter_title_2",
      position: "center",
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1013188364?badge=0&autopause=0&player_id=0&app_id=58479",
      space: 6,
    },
    {
      type: "heading2",
      content: "advanced_filter_title_3",
      position: "center",
    },
  ],
};

const SuccessPopupVideo: ContentNode = {
  type: "page",
  children: [
    {
      type: "video",
      content: "https://player.vimeo.com/video/1022237787?h=faa20fbded&badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      type: "image",
      content: "/images/sportsBetting.jpg",
    },
  ],
};

const PositiveEVPage: ContentNode = {
  type: "page",
  children: [
    {
      type: "heading2",
      content: "positive_ev_title_1",
    },
    {
      type: "paragraph",
      content: "positive_ev_title_1_desc",
    },
    {
      type: "heading2",
      content: "positive_ev_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_title_2_desc",
    },
    {
      type: "heading2",
      content: "positive_ev_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_title_3_desc",
      space: 2,
    },
    {
      type: "paragraph",
      content: "positive_ev_title_3_desc_list_1",
      indent: 6.5,
      space: 3,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_title_3_desc_list_1_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_title_3_desc_list_1_2",
          indent: 13,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "positive_ev_title_3_desc_list_2",
      indent: 6.5,
      space: 3,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_title_3_desc_list_2_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_title_3_desc_list_2_2",
          indent: 13,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "positive_ev_title_3_desc_list_3",
      indent: 6.5,
      space: 3,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_title_3_desc_list_3_1",
          indent: 13,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "positive_ev_title_3_desc_list_4",
      indent: 6.5,
      space: 3,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_title_3_desc_list_4_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_title_3_desc_list_4_2",
          indent: 13,
          space: 3,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_title_3_desc_list_4_2_sub_1",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_title_3_desc_list_4_2_sub_2",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_title_3_desc_list_4_2_sub_3",
          indent: 19.5,
          space: 3,
        },
      ],
    },
    {
      type: "heading2",
      content: "positive_ev_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_title_4_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_title_4_desc_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_title_4_desc_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_title_4_desc_list_3",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "positive_ev_title_5",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_title_5_sub_1",
      indent: 6.5,
      space: 3,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_title_5_sub_1_list_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_title_5_sub_1_list_2",
          indent: 13,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "positive_ev_title_5_sub_2",
      indent: 6.5,
      space: 3,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_title_5_sub_2_list_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "positive_ev_title_5_sub_2_list_2",
          indent: 13,
          space: 3,
        },
      ],
    },
    {
      type: "heading2",
      content: "positive_ev_title_6",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_title_6_desc",
    },
  ],
};

interface ContentData {
  [key: string]: ContentNode;
}

export const contentData: ContentData = {
  sports_betting_101: SportsBetting101,
  arbitrage_betting: ArbitrageBetting,
  positive_ev_betting: PositiveEV,
  advanced_filters: AdvancedFilters,
  success_popup_video: SuccessPopupVideo,
  filter_basics: Filters,
  odds_screen_filter: OddsScreenFilters,
  odds_screen_page: OddsScreenPage,
  arbitrage_filters: ArbitrageFilters,
  arbitrage_page: ArbitragePage,
  positive_ev_filters: PositiveEVFilters,
  low_holds_page: LowHoldPage,
  middles_page: MiddlesPage,
  free_bet_converter: FreeBetConverter,
  setting: Setting,
  positive_ev_page: PositiveEVPage,
};

export const educations = [
  {
    title: "sports_betting_101",
    image: "/images/sportsBetting.jpg",
    description: "education_description_1",
  },
  {
    title: "arbitrage_betting",
    image: "/images/arbitrageBetting.png",
    description: "education_description_2",
  },
  {
    title: "positive_ev_betting",
    image: "/images/evBetting.png",
    description: "education_description_3",
  },
];

export const siteGuides = [
  {
    title: "filter_basics",
    image: "/images/filterBasics.png",
    description: "site_guides_description_1",
  },
  {
    title: "odds_screen_filter",
    image: "/images/oddsscreenfilter.png",
    description: "site_guides_description_2",
  },
  {
    title: "odds_screen_page",
    image: "/images/oddsscreen.png",
    description: "site_guides_description_3",
  },
  {
    title: "arbitrage_filters",
    image: "/images/arbitragefilters.png",
    description: "site_guides_description_4",
  },
  {
    title: "arbitrage_page",
    image: "/images/arbitrage.png",
    description: "site_guides_description_5",
  },
  {
    title: "positive_ev_filters",
    image: "/images/positiveEVfilters.png",
    description: "site_guides_description_6",
  },
  {
    title: "positive_ev_page",
    image: "/images/positiveev.png",
    description: "site_guides_description_7",
  },
  {
    title: "advanced_filters",
    image: "/images/advancedfilters.png",
    description: "site_guides_description_12",
  },
  {
    title: "middles_page",
    image: "/images/middles.png",
    description: "site_guides_description_8",
  },
  {
    title: "free_bet_converter",
    image: "/images/freebet.png",
    description: "site_guides_description_9",
  },
  {
    title: "low_holds_page",
    image: "/images/lowholds.png",
    description: "site_guides_description_10",
  },
  {
    title: "setting",
    image: "/images/settings.png",
    description: "site_guides_description_11",
  },
];

export const contentInfo = [
  {
    title: "sports_betting_101",
    image: "/images/sportsBetting.jpg",
    type: "image",
  },

  {
    title: "arbitrage_betting",
    image: "https://player.vimeo.com/video/1013508106?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "positive_ev_betting",
    image: "/images/evBetting.png",
    type: "image",
  },
  {
    title: "filter_basics",
    image: "https://player.vimeo.com/video/1012860529?badge=0&autopause=0&player_id=0&app_id=58479",
    type: "video",
  },
  {
    title: "odds_screen_filter",
    image: "https://player.vimeo.com/video/1012861357?badge=0&autopause=0&player_id=0&app_id=58479",
    type: "video",
  },
  {
    title: "odds_screen_page",
    image: "https://player.vimeo.com/video/1012864423?badge=0&autopause=0&player_id=0&app_id=58479",
    type: "video",
  },
  {
    title: "arbitrage_filters",
    image: "https://player.vimeo.com/video/1012859410?badge=0&autopause=0&player_id=0&app_id=58479",
    type: "video",
  },
  {
    title: "arbitrage_page",
    image: "https://player.vimeo.com/video/1012859938?badge=0&autopause=0&player_id=0&app_id=58479",
    type: "video",
  },
  {
    title: "positive_ev_filters",
    image: "https://player.vimeo.com/video/1012860203?badge=0&autopause=0&player_id=0&app_id=58479",
    type: "video",
  },
  {
    title: "positive_ev_page",
    image: "https://player.vimeo.com/video/1012872266?badge=0&autopause=0&player_id=0&app_id=58479",
    type: "video",
  },
  {
    title: "advanced_filters",
    image: "",
    type: "video",
  },
  {
    title: "middles_page",
    image: "https://player.vimeo.com/video/1012861133?badge=0&autopause=0&player_id=0&app_id=58479",
    type: "video",
  },
  {
    title: "free_bet_converter",
    image: "https://player.vimeo.com/video/1012888842?badge=0&autopause=0&player_id=0&app_id=58479",
    type: "video",
  },
  {
    title: "low_holds_page",
    image: "https://player.vimeo.com/video/1012906266?badge=0&autopause=0&player_id=0&app_id=58479",
    type: "video",
  },
  {
    title: "setting",
    image: "https://player.vimeo.com/video/1012890203?badge=0&autopause=0&player_id=0&app_id=58479",
    type: "video",
  },
];
